<template>
	<div id="office">
		<div class="header">
			<div class="lefthead">
				<img :src="logo">
				<span>{{ $bus.webName }}</span>
			</div>
			<label for="toggle-nav">
				<div class="border-box">
					<div class="border"></div>
				</div>
			</label>
			<input type="checkbox" id="toggle-nav">
			<div class="righthead">
				<router-link :to="item.link" active-class="active" v-for="(item,index) in navigate" :key="index">
					{{item.name}}</router-link>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				logo: this.$context(this.$bus.img.web_logo), //logo
				navigate: [{
						name: this.$bus.home,
						link: 'space'
					},
					{
						name: this.$bus.about,
						link: 'About'
					},
					{
						name: this.$bus.download,
						link: 'Download'
					},
				]
			}
		},
		mounted() {
			//动态设置 网站标题
			document.title = this.$bus.webName
			//动态设置网站 图标 
			var link = document.querySelector('link[rel*="icon"]');
			// link.href ="http://img.alicbin.com/img/20210327132512.png"
			link.href = this.$context(this.$bus.img.web_logo);

		}
	}
</script>

<style lang="less">
	body {
		margin: 0;
	}
	a,input,label{-webkit-tap-highlight-color:rgba(255,0,0,0);} 
	input {
		display: none;
	}
	.border-box {
		display: none;
		color: rgba(255, 255, 255, 0.5);
		height: 20px;
		cursor: pointer;
		.border {
			height: 1px;
			width: 10px;
			background-color: rgba(255, 255, 255, 0.5) ;
			position: relative;
			&::before {
				display: block;
				content: "";
				height: 1px;
				width: 10px;
				background-color: rgba(255, 255, 255, 0.5) ;
				position: absolute;
				top:3px;
			}
			&::after {
				display: block;
				content: "";
				height: 1px;
				width: 10px;
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				bottom:3px;
			}
		}
	}
	
	#office {
		padding-left: 15.1px;
		padding-right: 15.1px;

		.header {
			display: flex;
			justify-content: space-between;
			padding-top: 10.75px;
		}
	}
	.righthead {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
	}
	
	.righthead a {
		text-decoration: none;
		color: #010101;
		font-weight: bold;
		font-size: 4.2px;
		line-height: 6.8px;
		margin-left: 9.43px;
		//    border-bottom: 3px solid #505050;
	}

	.active {
		border-bottom: 0.56px solid #505050;
	}

	.lefthead {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 8.49px;
			width: 8.49px;
		}

		span {
			color: #505050;
			font-weight: bold;
			font-size: 4.2px;
			margin-left: 1.88px;
		}
	}
	
	// @media screen and (min-width: 768px) and (max-width:992px) {
	@media screen and (max-width:992px) {
		#office {
			padding-left: 0px;
			padding-right: 0px;

			.header {
				background-color: black;
				display: flex;
				justify-content: space-between;
				padding:8.1px 15.75px;
				flex-wrap: wrap;
				label {
					width: 80%;
					.border-box {
						display: flex;
						justify-content: flex-end;
						align-items: center;
					}
				}
			}
		}

		.righthead {
			height: 0;
			opacity: 0;
			display: flex;
			flex-grow: 1;
			justify-content: flex-start;
			flex-wrap: wrap;
			transition:0.5s;
			overflow: hidden;
		}
		
		.righthead a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: #FFFFFF;
			font-weight: 500;
			font-size: 7px;
			margin-left: 0;
			width:100%;
			padding:7px 0;
			border-bottom: 0.56px solid transparent;
		}

		.active {
			border-bottom: 0.56px solid #FFFFFF!important;
		}
		
		input:checked {
			&+.righthead {
				height: 60px;
				opacity: 1;
			}
		}

		.lefthead {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: 15px;
				width: 15px;
				margin-right: 8px;
			}

			span {
				color: #FFFFFF;
				font-weight: bold;
				font-size: 7px;
			}
		}
	}
	
	@media screen and (max-width:500px) {
		#office {
			.header {
				padding:15.1px 20.75px;
				label {
					width: 68%;
				}
			}
		}
	

		.border-box {
			height: 35px;
			.border {
				height: 3px;
				width: 20px;
				&::before {
					width: 20px;
					height: 3px;
					top:6px;
				}
				&::after {
					width: 20px;
					height: 3px;
					bottom:6px;
				}
			}
		}
		
		.righthead a {
			font-size: 15px;
		}
		
		input:checked {
			&+.righthead {
				height: 160px;
				opacity: 1;
			}
		}
	
		.lefthead {
			img {
				height: 30px;
				width: 30px;
				margin-right: 8px;
			}
	
			span {
				color: #FFFFFF;
				font-weight: bold;
				font-size: 15px;
			}
		}
	}
	
</style>
