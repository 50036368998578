
export default {
    webName:"一见岚官方", //网站名
    appName:"一见岚LR",  //应用名
    appName_full:"一见岚小程序",  //应用名 全名
    appName_full_en:"一见岚小程序",  //应用名 英文
    short_desc:"高品质且独具原创特色的蛋糕及甜品", //简短介绍
    description:'品牌名"La Rencontre"源自法语，中文名为“一见岚法语名与中文名均传达着“初会的美好”之意味。',
    home:"主页",     //导航
    about:"关于",    //导航
    download:"小程序", //导航
    download_url:"http://www.baidu.com", //下载链接
    beian:"粤ICP备2023020576号-1",  //备案号
    company:"创森（广州）投资咨询有限公司", // 公司名
    img:{
      logo:"./logo.png", // app logo 图标
      web_logo:"./logo.png", // 网站 logo 图标
      score:"./score.png", //评分 图标
      circle:"./circle.png", //背景圆  图标
      android_frame:"./android_frame.png", // 安卓边框
      ios_frame:"./ios_frame.png", //ios 边框
      android_code:"./mini.png", // 安卓下载二维码
      ios_code:"./ma.png",     // ios 下载二维码
      app_img_1:"./app_img_1.png", //  主页介绍图 1
      app_img_2:"./app_img_2.png", //  主页介绍图 2
      app_img_3:"./app_img_3.png", //  关于 介绍图 1
      app_img_4:"./app_img_4.png", //  关于 介绍图 2
    },
    about_text:[ //关于的介绍文本
        "LR·一见岚创立于 2018 年，致力于研发符合东方人口味和心意的高级西点，在甜品的世界中探索自己的独特表达。从本土向世界出发，在世界热爱本土，是 LR·一见岚的灵感源泉。我们从世界各地甄选顶级食材，并引入国际先进的管理理念和硬件设备进行全新创造，将国际化的智慧”进行“本土化适配”，持续研发制作出高品质且独具原创特色的蛋糕及甜品。"
    ],

}
